import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ListAuto from "../ListAuto";
import React from "react";
import {findGeneral, getLineDate} from "../../Rest/RestFunction";
import './styleFindGeneral.css';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AutoComplete from "../AutoComplete";


export default function FindGeneral(props) {

    const [requestAuto, setRequestAuto] = React.useState(false);
    const [auto, setAuto] = React.useState();
    const [count, setCount] = React.useState({right: "", left: "", nomer:"", marka:""});
    const [listNomer, setListNomer] = React.useState([]);
    const [listMarka, setListMarka] = React.useState([]);


    React.useEffect(() => {
        let listNomer = JSON.parse(localStorage.getItem("nomer"))
        let listMarka = JSON.parse(localStorage.getItem("marka"))
        if (listNomer != null) {
            setListNomer(listNomer)
        }

        if (listMarka != null) {
            setListMarka(listMarka)
        }
        document.addEventListener("keydown", enterDown);
        document.removeEventListener("keydown", enterDown);
    },[]);

    const ch = (nameProperty) => (e) => {
            setCount({
                ...count,
                [nameProperty]: e.target.value

            });
    }


    const FindAll = async (item) => {
        let ListAuto = await findGeneral(count);
        setAuto(ListAuto);
        setRequestAuto(true);
        setCount(count);

        if (count.nomer != null && count.nomer.length>0) {
            let countElements = 50;

            if (listNomer.includes(count.nomer)) {
                listNomer.splice(listNomer.indexOf(count.nomer), 1)
            } else {
                if (listNomer.length > countElements) {
                    listNomer.splice(listNomer.length-1, 1)
                }
            }
            listNomer.unshift(count.nomer);
            localStorage.setItem("nomer", JSON.stringify(listNomer));
        }

        if (count.marka != null && count.marka.length>0) {
            let countElementsMarka = 50;

            if (listMarka.includes(count.marka)) {
                listMarka.splice(listMarka.indexOf(count.marka), 1)
            } else {
                if (listMarka.length > countElementsMarka) {
                    listMarka.splice(listMarka.length-1, 1)
                }
            }
            listMarka.unshift(count.marka);
            localStorage.setItem("marka", JSON.stringify(listMarka));
        }


    };

   const LineDate = async (item) => {
        let Line =  await getLineDate(count)
        setAuto(Line);
        setRequestAuto(true);
    };


    const enterDown = (e) => {
        if (e.keyCode === 13) {
            FindAll()
        }
    }

    const resetAuto = () => {
        LineDate()
    }

    const clearFind = (name) => () => {

        setCount({
            ...count,
            [name]: ""

        })

    }


    return (

        <div className="App_Find_General">

            <h2> Общий поиск: </h2>

            <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap"}}>
            <TextField
                className="App_Find_General_Text_Field"
                id="data"
                label="Дата с:"
                type="date"
                name="left"
                defaultValue=""
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={ch("left")}
                onKeyDown={enterDown}

                value={count.left}

                InputProps={{
                    endAdornment: (
                        <IconButton size="small" aria-label="Clear"
                                    onClick={clearFind('left')}>
                            <CloseIcon/>
                        </IconButton>
                    )
                }}
            />

            <TextField className="App_Find_General_Text_Field"
                       id="data"
                       label="Дата по:"
                       type="date"
                       name="right"
                       defaultValue=""
                       InputLabelProps={{
                           shrink: true,
                       }}
                       value={count.right}

                       InputProps={{
                           endAdornment: (
                               <IconButton size="small" aria-label="Clear"
                                           onClick={clearFind('right')}>
                                   <CloseIcon/>
                               </IconButton>
                           )
                       }}

                       onChange={ch("right")}
                       onKeyDown={enterDown}
            />

            {/*<TextField className="Betwen_Find_General"*/}
            {/*           name="nomer"*/}
            {/*           label="Номер"*/}
            {/*           rowsMax={1}*/}
            {/*           value={value}*/}
            {/*           onChange={ch}*/}
            {/*           onKeyDown={enterDown}*/}
            {/*/>*/}

            <AutoComplete
                          style={{width:"200px"}}
                          className="App_Find_General_Text_Field"
                          name="nomer"
                          label="Номер"
                          rowsMax={1}
                          value={count.nomer}
                          options={listNomer}
                          onChange={ch("nomer")}
                          onChangeAutoComplete={(event, value) => {
                              setCount({
                                  ...count,
                                  "nomer":value
                              })
                          }}
                          onKeyDown={enterDown}  > </AutoComplete>

                <AutoComplete
                    style={{width:"200px"}}
                    className="App_Find_General_Text_Field"
                    name="marka"
                    label="Марка"
                    rowsMax={1}
                    value={count.marka}
                    options={listMarka}
                    onChange={ch("marka")}
                    onChangeAutoComplete={(event, value) => {
                        setCount({
                            ...count,
                            "marka":value
                        })
                    }}
                    onKeyDown={enterDown}  >     </AutoComplete>


            {/*<TextField className="App_Find_General_Text_Field"*/}
            {/*           id=""*/}
            {/*           name="marka"*/}
            {/*           label="Марка"*/}
            {/*           rowsMax={1}*/}
            {/*           onChange={ch("marka")}*/}
            {/*           onKeyDown={enterDown}*/}
            {/*/>*/}


            <Button className="App_Find_General_Text_Field Button_Find_General" variant="contained"
                    onClick={FindAll}>
                Найти
            </Button>

            </div>

            {requestAuto === true ?

                auto.length > 0 ?
                    <div className="table_center_Find_General">
                        <div className="indent_Find_General">
                            <ListAuto auto={auto} resetList={resetAuto}/>
                        </div>
                    </div>
                    :
                    <div className="notFind_Find_General">
                        Ничего не найдено
                    </div>
                :
                null
            }

        </div>

    );
}
