import { createStore, applyMiddleware } from 'redux'
import createRootReducer from './reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory();

const middleware = routerMiddleware(history);

export const store = createStore(
    createRootReducer(history),
    composeWithDevTools(
        applyMiddleware(thunk, middleware)
    )
);