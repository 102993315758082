import React, {Fragment} from 'react';
import './styleAutoUpdate.css';
import {updateAuto, getAuto,addFiles} from '../../Rest/RestFunction.js';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DialogAddFiles from "../DialogAddFiles";
import {renderFilesListWithName} from "../../utils/commonUtils";
import AutoComplete from "../AutoComplete";


const style = {
    marginLeft:50
}

export default class AutoUpdate extends React.Component {
    constructor() {
        super();
        this.state = {

            responseBack:{
                marka:"",
                nomer:"",
                message:"",
                typeResult:"success",
            },

            showMessage:false,

            countTextField: {
                date: true,
                marka:true,
                nomer:true,
                zakup:true,
                prodal:true,
                dostavka:true,
                summa:true,
            },

            count: {
                date: "",
                marka:"",
                nomer:"",
                zakup:"",
                prodal:"",
                dostavka:"",
                summa:"",
                comment:"",
                files:[]
            },
            newFiles:[],
            allFiles:[],
            listMarka:[],
            listNomer:[],
            files:[],
        }
    }

    async componentDidMount() {
       // const {routeProps: {match: {params: {id,name,role}}}} = this.props;
        const id = this.props.match.params.id;
        let count = await getAuto(id)
        this.setState({count: {
                date: count.date,
                marka: count.marka,
                nomer: String(count.nomer),
                zakup: String(count.zakup),
                prodal:String(count.prodal),
                dostavka:String(count.dostavka),
                comment:count.comment,
                id:id,
                files:count.files
            }
        })
        let listMarka  = JSON.parse(localStorage.getItem("marka"))
        if (listMarka != null) {
            this.setState({
                listMarka:listMarka
            })
        }
        let listNomer  = JSON.parse(localStorage.getItem("nomer"))
        if (listNomer != null) {
            this.setState({
                listNomer:listNomer
            })
        }
    }

    ch = (nameProperty)=>(e) => {
        this.setState({
            count: {
                ...this.state.count,
                [nameProperty]: e.target.value
            }
        })
    }

    add = async (item) => {
        if (this.state.count.nomer != null && this.state.count.nomer.length > 0) {
            let countElements = 50;

            if (this.state.listNomer.includes(this.state.count.nomer)) {
                this.state.listNomer.splice(this.state.listNomer.indexOf(this.state.count.nomer), 1)
            } else {
                if (this.state.listNomer.length > countElements) {
                    this.state.listNomer.splice(this.state.listNomer.length - 1, 1)
                }
            }
            this.state.listNomer.unshift(this.state.count.nomer);
            localStorage.setItem("nomer", JSON.stringify(this.state.listNomer));
        }

        if (this.state.count.marka != null && this.state.count.marka.length > 0) {
            let countElementsMarka = 50;

            if (this.state.listMarka.includes(this.state.count.marka)) {
                this.state.listMarka.splice(this.state.listMarka.indexOf(this.state.count.marka), 1)
            } else {
                if (this.state.listMarka.length > countElementsMarka) {
                    this.state.listMarka.splice(this.state.listMarka.length - 1, 1)
                }
            }
            this.state.listMarka.unshift(this.state.count.marka);
            localStorage.setItem("marka", JSON.stringify(this.state.listMarka));
        }

        if (this.state.count.marka.length<=2) {
            this.state.countTextField.marka = false
        }

        if (this.state.count.nomer.length<=2) {
            this.state.countTextField.nomer = false
        }

        if (this.state.count.date.length<=2) {
            this.state.countTextField.date = false
        }

        if (this.state.count.zakup.length<1) {
            this.state.countTextField.zakup = false
        }

        if (this.state.count.prodal.length<1) {
            this.state.countTextField.prodal = false
        }

        if (this.state.count.dostavka.length<1) {
            this.state.countTextField.dostavka = false
        }
        if (this.state.count.marka.length >= 2 && this.state.count.date.length >= 2 && this.state.count.nomer.length >= 2
            && this.state.count.zakup.length >=1 && this.state.count.prodal.length >=1 && this.state.count.dostavka.length >=1) {

            let quid_files = [];
            if (this.state.newFiles.length > 0) {
                quid_files = await addFiles(this.state.newFiles)
            }
            quid_files.forEach(quid => {
                this.state.count.files.push({
                    guid: quid,
                })
            })

            const responseUpdateAuto = await updateAuto(this.state.count, this.props.userId);

            const id = this.props.match.params.id;
            let count = await getAuto(id)
            this.setState({count: {
                    date: count.date,
                    marka: count.marka,
                    nomer: String(count.nomer),
                    zakup: String(count.zakup),
                    prodal:String(count.prodal),
                    dostavka:String(count.dostavka),
                    comment:count.comment,
                    id:id,
                    files:count.files
                },
                newFiles:[],
                responseBack:responseUpdateAuto,
                showMessage:true,

            })
            this.setState({
                count: {
                    ...this.state.count,
                    date: "",
                    marka:"",
                    nomer:"",
                    zakup:"",
                    prodal:"",
                    dostavka:"",
                    summa:"",
                    comment:"",
                    files:[]
                }

            })
            this.setState({
                files:[]
            })
        }
        else{
            setTimeout(() => {
                this.setState({
                    countTextField: {
                        ...this.state.countTextField,
                        marka: true,
                        nomer:true,
                        date:true,
                        zakup:true,
                        prodal:true,
                        dostavka:true
                    }
                })
            }, 2000)
        }
        this.setState({})
    };

    valueSum = () => {
        const { dostavka, prodal, zakup} = this.state.count
        if ( !(zakup==="" || prodal==="" || dostavka==="")) {
            let summa = prodal - zakup - dostavka
            this.state.count.summa=summa
            return summa
        }
        else
            return ""
    }

    messageClose = () => {
        this.setState({
            showMessage:false
        })
    }

    handleChangeFiles = (files) => {
        this.setState({
            newFiles: files
        })
    }

    deleteFileFunction = (index) => () => {
        if (index<this.state.count.files.length) {
            this.state.count.files.splice(index,1)
        } else {
            this.state.newFiles.splice(index-this.state.count.files.length, 1)
        }
        this.setState({})
    }

    render() {
        this.state.allFiles = this.state.count.files.concat(this.state.newFiles)
        return (
            <div className="App_AutoUpdate">

                <TextField
                    id="data_AutoUpdate"
                    label="Дата:"
                    type="date"
                    name="date"
                    className={this.state.countTextField.date ? "" : "red_animation"}
                    defaultValue= ""
                    InputLabelProps={{
                        shrink:true,
                    }}
                    value={this.state.count.date.substring(0,10)}
                    onChange={this.ch("date")}
                /> <br/>




                <div className="forma_Update_Auto">


                    <AutoComplete
                        id="marka_textfield"
                        name="marka"
                        className={this.state.countTextField.marka ? "" : "red_animation"}
                        label="Марка"
                        multiline
                        rowsMax={4}
                        value={this.state.count.marka}
                        onChange={this.ch("marka")}
                        options={this.state.listMarka}
                        onChangeAutoComplete={(event, value) => {
                            this.setState({
                                count: {
                                    ...this.state.count,
                                    marka: value
                                }
                            })
                        }}

                    > </AutoComplete>

                    <br/>
                    <AutoComplete
                        id="marka_textfield"
                        name="nomer"
                        className={this.state.countTextField.nomer ? "" : "red_animation"}
                        label="Номер"
                        multiline
                        rowsMax={4}
                        value={this.state.count.nomer}
                        onChange={this.ch("nomer")}

                        options={this.state.listNomer}
                        onChangeAutoComplete={(event, value) => {
                            this.setState({
                                count: {
                                    ...this.state.count,
                                    nomer: value
                                }
                            })
                        }}

                    > </AutoComplete>
                    <br/>
                    <TextField
                        id="text_AutoUpdate"
                        name="zakup"
                        className={this.state.countTextField.zakup ? "" : "red_animation_AutoUpdate"}
                        label="Закупка"
                        type="number"
                        value={this.state.count.zakup}
                        onChange={this.ch("zakup")}

                    />
                    <br/>
                    <TextField
                        input type="number"
                        id="text_AutoUpdate"
                        name="prodal"
                        className={this.state.countTextField.prodal ? "" : "red_animation_AutoUpdate"}
                        label="Продажа"
                        type="number"
                        value={this.state.count.prodal}
                        onChange={this.ch("prodal")}
                    />
                    <br/>

                    <TextField
                        input type="number"
                        id="text_AutoUpdate"
                        name="dostavka"
                        className={this.state.countTextField.dostavka ? "" : "red_animation_AutoUpdate"}
                        label="Доставка"
                        type="number"
                        value={this.state.count.dostavka}
                        onChange={this.ch("dostavka")}
                    />
                    <br/>
                    <TextField
                        input type="number"
                        id="text_AutoUpdate"
                        className="pole_text_AutoUpdate"
                        name="summa"
                        label="Сумма"
                        type="number"
                        value={this.valueSum()}
                        disabled={true}
                        onChange={this.ch("summa")}

                    />
                    <br/>

                    <TextField
                        id="text_AutoUpdate"
                        name="comment"
                        label="Комментарий"
                        type="text"
                        multiline
                        value={this.state.count.comment}
                        onChange={this.ch("comment")}
                    />

                    <div style={{display:"flex", justifyContent:"space-between", marginTop:"30px"}}>
                        <div>
                            {renderFilesListWithName(this.state.allFiles, this.deleteFileFunction)}
                        </div>

                        <DialogAddFiles className="Button_Add_Auto"
                                        handleChangeFiles = {this.handleChangeFiles}
                                        files = {this.state.newFiles}
                        />
                    </div>
                </div>

                <div className= "Button_Auto_Update">

                    <Button variant="contained" style={{marginTop:"0px"}} 
                            onClick={this.add}>
                        Сохранить запись
                    </Button>

                </div>

                <Snackbar open={this.state.showMessage} autoHideDuration={3000} onClose={this.messageClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.messageClose} severity={this.state.responseBack.typeResult}>
                        {this.state.responseBack.marka}  {this.state.responseBack.nomer}  {this.state.responseBack.message}
                    </MuiAlert>
                </Snackbar>

            </div>

        );

    }
}

