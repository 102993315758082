import React, {Fragment} from 'react';
import './styleFineDate1.css';
import {findDate} from '../../Rest/RestFunction.js';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import ListCount from "../ListCount";
import ListAuto from "../ListAuto";


export default class FindDate extends React.Component {
    constructor() {
        super();
        this.state = {

            requestAuto:false,

            auto: [],

            count: {
                date: "",
            }
        }
    }

    componentDidMount() {
        const nowDate = new Date();
        nowDate.setHours(nowDate.getHours() + 3);
        this.setState({
            count:{
                date: nowDate.toISOString().substring(0,10),
            }
        })
    }

    ch = (e) => {
        this.setState({
            count: {
                ...this.state.count,
                [e.target.name]: e.target.value
            }
        })

    }

    findDate1 = async (item) => {
        let finddateone = await findDate( this.state.count.date)
        this.setState(
            {auto: finddateone,
                requestAuto: true
            }
        )

    } ;
    enterDown = (e) => {
        if(e.keyCode === 13) {
            this.findDate1()
        }
    }


    resetAuto = () => {
        this.findDate1()
    }


    render() {
        return (
            <div className="App_Find_Date1">



                            <h2> Поиск по дате: </h2>

                            <TextField
                                required
                                label="Дата:"
                                type="date"
                                name="date"
                                defaultValue=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.count.date}
                                onChange={this.ch}
                                onKeyDown={this.enterDown}
                            /><br/><br/>


                    <form className="form"_Find_Date1>

                        
                        <Button  variant="contained"
                                onClick={this.findDate1}>
                            Запросить
                        </Button>


                    </form>
                            {this.state.requestAuto === true ?

                                this.state.auto.length>0 ?
                                    <div className="table_center_Find_Date1">
                                        <div className="indent_Find_Date1">
                <ListAuto auto={this.state.auto}  resetList = {this.resetAuto}/>
                                        </div>
                                    </div>
                                    :
                                    <div className="notFind_Date1">
                                        Ничего не найдено
                                    </div>

                                :
                                null
                            }






            </div>




        );

    }
}

