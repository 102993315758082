import React, {Fragment} from 'react';
import './style4.css';
import {getLineDate} from '../../Rest/RestFunction.js';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import ListAuto from "../ListAuto";

const style = {
    marginLeft:50
}

export default class FindLineDate extends React.Component {
    constructor() {
        super();
        this.state = {

            requestAuto:false,

            auto: [],

            count: {

            }
        }
    }
    componentDidMount() {

    }

    ch = (e) => {
        this.setState({
            count: {
                ...this.state.count,
                [e.target.name]: e.target.value
            }
        })

    }


    LineDate = async (item) => {
        let Line =  await getLineDate(this.state.count)
        this.setState(
            {auto:  Line,
            requestAuto: true}
        )

    };
    enterDown = (e) => {
        if(e.keyCode === 13) {
            this.LineDate()
        }
    }

    resetAuto = () => {
        this.LineDate()
    }


    render() {
        return (
            <div className="App_Find_Line_Date">

                <h2> Поиск за период: </h2>

                <TextField
                    id="data"
                    label="Дата с:"
                    type="date"
                    name="left"
                    defaultValue= ""
                    InputLabelProps={{
                        shrink:true,
                    }}
                    onChange={this.ch}
                    onKeyDown={this.enterDown}
                />

                <TextField className="Betwen_Find_Line_Date"
                    id="data"
                    label="Дата по:"
                    type="date"
                    name="right"
                    defaultValue= ""
                    InputLabelProps={{
                        shrink:true,
                    }}
                    onChange={this.ch}
                           onKeyDown={this.enterDown}
                />
                <br/>

                <br/><br/>

                    <Button variant="contained"
                            onClick={this.LineDate}>
                        Запросить
                    </Button>

                {this.state.requestAuto === true ?

                    this.state.auto.length>0 ?
                        <div className="table_center_Find_Line_Date">
                            <div className="indent_Find_Line_Date">
                                <ListAuto auto={this.state.auto} resetList = {this.resetAuto}/>
                            </div>
                        </div>
                        :
                        <div className="notFind_Find_Line_Date">
                        Ничего не найдено
                        </div>
                    :
                    null
                }


            </div>




        );

    }
}

