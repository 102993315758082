
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from "./auth/reducer"

const reducers = {
    auth
};

export default (history) => combineReducers({
    router: connectRouter(history),
    ...reducers
});