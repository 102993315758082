/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutoComplete(props) {

    return (
            <Autocomplete
                className={props.className}
                style={props.style}
                freeSolo
                inputValue={props.value}
                options={props.options && props.options.map((option) => option)}
                name={props.name}
                clearText={"Очистить"}
                onInputChange={props.onChangeAutoComplete}
                renderInput={(params) => (
                    <TextField {...params} name={props.name} rowsMax={props.rowsMax} label={props.label} value={props.value} onKeyDown={props.onKeyDown} onChange={props.onChange} />
                )}
            />
    );
}
