import React, {Fragment} from 'react';
import './style1.css';
import {addRest, getAuto, addFiles} from '../../Rest/RestFunction.js';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import AutoComplete from "../AutoComplete";
import DialogAddFiles from "../DialogAddFiles";
import {renderFilesListWithName} from "../../utils/commonUtils";

export default class AddAuto extends React.Component {
    constructor() {
        super();

        this.state = {

            responseBack:{
                marka:"",
                nomer:"",
                message:"",
                typeResult:"success",
            },

            showMessage:false,

            countTextField: {
                date: true,
                marka:true,
                nomer:true,
                zakup:true,
                prodal:true,
                dostavka:true,
                summa:true,
            },

            count: {
                date: "",
                marka:"",
                nomer:"",
                zakup:"",
                prodal:"",
                dostavka:"",
                summa:"",
            },
            listMarka:[],
            listNomer:[],
            files:[]
        }
    }
block=(e)=> {
    document.getElementById("two").innerHTML = "Прикрепить файлы";
    };
    blockOut=(e)=> {
        document.getElementById("two").innerHTML = null;
    };


componentDidMount() {
       // document.body.addEventListener('mouseover',this.block);
   // document.getElementById("one").addEventListener("mouseover", this.block)

       let listMarka  = JSON.parse(localStorage.getItem("marka"))
    if (listMarka != null) {
        this.setState({
            listMarka:listMarka
        })
    }
    let listNomer  = JSON.parse(localStorage.getItem("nomer"))
    if (listNomer != null) {
        this.setState({
            listNomer:listNomer
        })
    }
}


    ch = (nameProperty)=>(e) => {
        this.setState({
            count: {
                ...this.state.count,
                [nameProperty]: e.target.value
            }
        })
    }

    add = async (item) => {

        if (this.state.count.nomer != null && this.state.count.nomer.length > 0) {
            let countElements = 50;

            if (this.state.listNomer.includes(this.state.count.nomer)) {
                this.state.listNomer.splice(this.state.listNomer.indexOf(this.state.count.nomer), 1)
            } else {
                if (this.state.listNomer.length > countElements) {
                    this.state.listNomer.splice(this.state.listNomer.length - 1, 1)
                }
            }
            this.state.listNomer.unshift(this.state.count.nomer);
            localStorage.setItem("nomer", JSON.stringify(this.state.listNomer));
        }

        if (this.state.count.marka != null && this.state.count.marka.length > 0) {
            let countElementsMarka = 50;

            if (this.state.listMarka.includes(this.state.count.marka)) {
                this.state.listMarka.splice(this.state.listMarka.indexOf(this.state.count.marka), 1)
            } else {
                if (this.state.listMarka.length > countElementsMarka) {
                    this.state.listMarka.splice(this.state.listMarka.length - 1, 1)
                }
            }
            this.state.listMarka.unshift(this.state.count.marka);
            localStorage.setItem("marka", JSON.stringify(this.state.listMarka));
        }

        if (this.state.count.marka.length<=2) {
            this.state.countTextField.marka = false
        }

        if (this.state.count.nomer.length<=2) {
            this.state.countTextField.nomer = false
        }

        if (this.state.count.date.length<=2) {
            this.state.countTextField.date = false
        }

        if (this.state.count.zakup.length<1) {
            this.state.countTextField.zakup = false
        }

        if (this.state.count.prodal.length<1) {
            this.state.countTextField.prodal = false
        }

        if (this.state.count.dostavka.length<1) {
            this.state.countTextField.dostavka = false
        }

        if (this.state.count.marka.length >= 2 && this.state.count.date.length >= 2 && this.state.count.nomer.length >= 2
            && this.state.count.zakup.length >=1 && this.state.count.prodal.length >=1 && this.state.count.dostavka.length >=1) {

            let quid_files = [];
            if (this.state.files.length > 0) {
                quid_files = await addFiles(this.state.files)
            }
            this.state.count.files= quid_files.map(quid => {
                return {
                    guid: quid
                }
            })

            const responseAddAuto = await addRest(this.state.count);
            this.setState({
                count: {
                    ...this.state.count,
                    date: "",
                    marka:"",
                    nomer:"",
                    zakup:"",
                    prodal:"",
                    dostavka:"",
                    summa:"",
                    comment:"",
                    files:[]
                },
                responseBack: responseAddAuto,
                showMessage: true,
            })
                this.setState({
                    files:[]
                })
            }

        else {
            setTimeout(() => {
                this.setState({
                    countTextField: {
                        ...this.state.countTextField,
                        marka: true,
                        nomer:true,
                        date:true,
                        zakup:true,
                        prodal:true,
                        dostavka:true
                    }
                })
            }, 2000)
        }
        this.setState({})
    };



    get1 = async (item) => {
        let auto1 =  await getAuto(this.state.id)
        this.setState(
            {auto:  auto1}
        )

    };

    valueSum = () => {
        const { dostavka, prodal, zakup} = this.state.count
        if ( !(zakup==="" || prodal==="" || dostavka==="")) {
            let summa = prodal - zakup - dostavka
            this.state.count.summa=summa
            return summa
        }
        else
            return ""
    }
    messageClose = () => {
        this.setState({
            showMessage:false
        })
    }

    handleChangeFiles = (files) => {
        this.setState({
            files: files
        })
    }

    deleteFileFunction = (index) => () => {
        this.state.files.splice(index, 1)
        this.setState({})
    }


    render() {
        return (

            <div className="App">

                <TextField
                    id="data"
                    label="Дата:"
                    type="date"
                    name="date"
                    value={this.state.count.date}
                    className={this.state.countTextField.date ? "" : "red_animation"}
                    defaultValue= ""
                    InputLabelProps={{
                        shrink:true,
                    }}
                    onChange={this.ch("date")}
                />


                <br/>




                <div className="forma">

                    <AutoComplete
                        id="marka_textfield"
                        name="marka"
                        className={this.state.countTextField.marka ? "" : "red_animation"}
                        label="Марка"
                        multiline
                        rowsMax={4}
                        value={this.state.count.marka}
                        onChange={this.ch("marka")}
                        options={this.state.listMarka}
                        onChangeAutoComplete={(event, value) => {
                            this.setState({
                                count: {
                                    ...this.state.count,
                                    marka: value
                                }
                            })
                        }}

                    > </AutoComplete>

                    <br/>

                    <AutoComplete
                        id="marka_textfield"
                        name="nomer"
                        className={this.state.countTextField.nomer ? "" : "red_animation"}
                        label="Номер"
                        multiline
                        rowsMax={4}
                        value={this.state.count.nomer}
                        onChange={this.ch("nomer")}

                        options={this.state.listNomer}
                        onChangeAutoComplete={(event, value) => {
                            this.setState({
                                count: {
                                    ...this.state.count,
                                    nomer: value
                                }
                            })
                        }}

                    > </AutoComplete>

                    <br/>
                    <TextField
                        id="marka_textfield"
                        name="zakup"
                        className={this.state.countTextField.zakup ? "" : "red_animation"}
                        value={this.state.count.zakup}
                        label="Закупка"
                        type="number"
                        onChange={this.ch("zakup")}

                    />
                    <br/>
                    <TextField
                        input type="number"
                        id="marka_textfield"
                        name="prodal"
                        className={this.state.countTextField.prodal ? "" : "red_animation"}
                        value={this.state.count.prodal}
                        label="Продажа"
                        type="number"
                        onChange={this.ch("prodal")}
                    />
                    <br/>

                    <TextField
                        input type="number"
                        id="marka_textfield"
                        name="dostavka"
                        className={this.state.countTextField.dostavka ? "" : "red_animation_AutoUpdate"}
                        value={this.state.count.dostavka}
                        label="Доставка"
                        type="number"
                        onChange={this.ch("dostavka")}
                    />
                    <br/>
                    <TextField
                        input type="number"
                        id="marka_textfield"
                        name="summa"
                        label="Сумма"
                        type="number"
                        value={this.valueSum()}
                        disabled={true}
                        onChange={this.ch("summa")}

                    />
                    <br/>

                    <TextField
                        input type="text"
                        id="marka_textfield"
                        name="comment"
                        label="Комментарий"
                        value={this.state.count.comment}
                        type="text"
                        onChange={this.ch("comment")}
                    /><br/><br/>

                    <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div id="one">
                            {renderFilesListWithName(this.state.files, this.deleteFileFunction)}
                        </div>

                        <DialogAddFiles className="Button_Add_Auto"
                                        handleChangeFiles = {this.handleChangeFiles}
                                        files = {this.state.files}
                        />
                    </div>
                </div>

                <div className="Button_Add_Auto">

                    <Button className="Button_Add_Auto" style={{marginTop:"40px", marginLeft:"15px"}}  variant="contained"
                            onClick={this.add}>
                        Сохранить запись
                    </Button>
                    <br/>
                </div>
                <div id="two" className= "IndentAddAuto"></div>

                <Snackbar open={this.state.showMessage} autoHideDuration={3000} onClose={this.messageClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.messageClose} severity={this.state.responseBack.typeResult}>
                        {this.state.responseBack.marka}  {this.state.responseBack.nomer}  {this.state.responseBack.message}
                    </MuiAlert>
                </Snackbar>

            </div>



        );

    }
}

