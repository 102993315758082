import React, {Fragment} from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from "react-router-dom";
import "./style.css"
import UserUpdate from "../UserUpdate";
import {shallowEqual, useSelector} from "react-redux";
import * as AuthSelectors from "../../store/auth/reducer";
import FindDate from "../FindDate";
import FindByNumber from "../FindByNumber";

export default function SimpleMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const user = useSelector((state) => state.auth.user);
    //Достаем из Redux
    //const user = useSelector(AuthSelectors.getUser, shallowEqual)

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (

        <div>
            <Button className="noUpperCase" aria-controls="simple-menu" style={{color:"white"}} aria-haspopup="true" onClick={handleClick}>
                Меню
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >

                <MenuItem ><Link to="/findGeneral" onClick={handleClose} >Общий поиск</Link></MenuItem>
                <MenuItem ><Link to="/" onClick={handleClose} >Добавить запись запчастей</Link></MenuItem>
                {/*<MenuItem ><Link to="/findLineDate" onClick={handleClose} >Найти за период </Link></MenuItem>*/}
                {/*<MenuItem ><Link to="/findByNumber" onClick={handleClose} >Найти по номеру </Link></MenuItem>*/}
                {/*<MenuItem ><Link to="/findDate" onClick={handleClose} >Найти по дате </Link></MenuItem>*/}
                {user.role.name === "Администратор" &&
                <Fragment>
                    <MenuItem><Link to="/add" onClick={handleClose}>Добавить пользователя </Link></MenuItem>
                    <MenuItem><Link to="/findUsers" onClick={handleClose}>Найти всех пользователей </Link></MenuItem>
                </Fragment>
                }
            </Menu>
        </div>
    );
}