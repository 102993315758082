import * as authSelectors from "./reducer";
import * as types from "./actionTypes";
import {shallowEqual, useSelector} from "react-redux";
import * as AuthSelectors from "./reducer";
import {checkLogPass} from "../../Rest/RestFunction";
import {store} from "../configureStore";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

export const checkAuth = () => (dispatch) => {
    let authResponseCrypt = sessionStorage.getItem("auth")
    if (authResponseCrypt !== null && authResponseCrypt !== undefined) {
        let authResponse = JSON.parse(CryptoAES.decrypt(authResponseCrypt, 'local').toString(CryptoENC))
        if (authResponse.auth === false) {
            authResponse.auth = null
        }
        dispatch({
            type: types.CHANGE_USER,
            user: {
                auth: authResponse.auth,
                id: authResponse.id,
                login: authResponse.login,
                role: authResponse.role,
                message: authResponse.message
            }
        });
    }
}

export const checkPassword = (login, password) => async (dispatch) => {
    let authResponse = await checkLogPass({
        login:login,
        password:password
    })
    let crypt = CryptoAES.encrypt(JSON.stringify(authResponse), 'local').toString();
    sessionStorage.setItem("auth", crypt)
    dispatch({
        type: types.CHANGE_USER,
        user:{
            auth: authResponse.auth,
            id: authResponse.id,
            login: authResponse.login,
            role: authResponse.role,
            message: authResponse.message
        }
    });
};

export const logOut = () => (dispatch) => {
    //localStorage.clear()
    sessionStorage.removeItem("auth")
    dispatch({
        type: types.CHANGE_USER,
        user:{
            auth: null,
            role: {
                name:""
            }
        }
    });
}

export const deleteAuthInformation = (cookies) => (dispatch) => {
    cookies.remove("idActiveUser")
    cookies.remove("auth")
   // dispatch({ type: types.DELETE_AUTH_INFORMATION });
};
