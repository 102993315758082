import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {checkLogPass} from '../../Rest/RestFunction.js';
import photoBmw from '../../static/bmw.png'
import "./styleLoginPassword.css"
import {useDispatch, useSelector} from "react-redux";
import * as AuthActions from "../../store/auth/action"

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Автозапчасти © '}

            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${photoBmw})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function LoginPassword() {
    const classes = useStyles();
    const [logPass, setLogPass]=React.useState({login:"", password:""});
    // const [auth, setAuth]=React.useState(null);
    const dispatch = useDispatch()

   const ch = (e) =>{
       setLogPass({...logPass, [e.target.name]: e.target.value})

   };

    const auth = useSelector((state) => state.auth.user.auth);
    const message = useSelector((state) => state.auth.user.message);

  const checkPassword = async ()=> {
      dispatch(AuthActions.checkPassword(logPass.login, logPass.password))
   };

  const enterDown = (e) => {
      if(e.keyCode === 13) {
          checkPassword()
      }
  }


    return (
        <Grid container component="main" className={classes.root} >

            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Вход
                    </Typography>
                    <div className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Login"
                            name="login"
                            autoComplete="email"
                            autoFocus
                            onChange={ch}
                            onKeyDown={enterDown}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={ch}
                            onKeyDown={enterDown}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={checkPassword}
                        >
                            Войти
                        </Button>

                        <Box mt={5}>
                            <Copyright />
                        </Box>
                        <div className="responseMessage">
                        {auth===false?<h1> {message}</h1>:null}
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}