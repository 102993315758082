
// let url = 'http://localhost:8888'; //- Когда разрабатываем локально без докера

// let url = 'http://localhost'; // - Тестирование работы локально на компьютере с помощью докера. ЭТо путь до nginx, который перенаправит нас по url на бекенд

 // let url = 'http://135.181.200.52'; // - Продуктивная среда на серваке по ip.

let url = 'http://ourzap.ru'; // - Продуктивная среда на серваке по домену.




 export async function getRequest(action) {
    const URL = `${url}/rest/${action}`;
    const response = await fetch(URL, {
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'GET'
    })
        .then(  (response) => response.json())
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
        });
    return response;

};

export async function postRequest(action,obj) {
    const URL = `${url}/rest/${action}`;
    const response = await fetch(URL, {
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'POST',
        body: JSON.stringify(obj)
    })
        .then(  (response) => response.json())
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
        });
    return response;
};

export async function putRequest(action,obj) {
    const URL = `${url}/rest/${action}`;
    const response = await fetch(URL, {
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'PUT',
        body: JSON.stringify(obj)
    })
        .then(  (response) => response.json())
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
        });
    return response;
};



export async function deleteRequest(action,obj) {
    const URL = `${url}/rest/${action}`;
    const response = await fetch(URL, {
        headers: {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'DELETE',
        body: JSON.stringify(obj)
    })
        .then(function (response) {
        })
        .catch(function (error) {
        });

};



export const addRest = async (obj) => {
   return  await postRequest('addHibernate', obj)
    //await putRequest("add",obj)

};
export const updateAuto = async (obj,idUser) => {
    return await putRequest('update?idUser='+idUser, obj)
    //await putRequest("add",obj)

};
export const addUser = async (obj) => {
    return await postRequest('user/addUser', obj)
};
export const getAllUsers = async () => {
    return await getRequest('user/allUsers')

};
export const getUser = async (id) => {
    return await getRequest('user/findByUserId'+id)

};

export const updateUser = async (obj,idUser) => {
    return await putRequest('user/update?idRedactor='+idUser, obj)
    //await putRequest("add",obj)

};

export const getAuto = async (id) => {
    return await getRequest('findById'+id)

};
export const checkLogPass = async (obj) => {
    return await postRequest('user/checkLogPass',obj)

};
export const findGeneral = async (obj) => {
    return await postRequest('findGeneral',obj)

};

export const getLineDate = async (obj) => {
    return await postRequest('findLine',obj)

};

export const findDate = async (date) => {
    return await postRequest('findDate',date)

};

export const findNomer = async (nomer) => {
    return await postRequest('findNomer?nomer='+ nomer)

};

export const delRest = async (id) => {
    await deleteRequest('del?id='+id,{} )
};

export const deleteByIdList = async (idList) => {
    await deleteRequest('deleteByIdList', idList)
}
export const deleteUsersList = async (usersList) => {
    await deleteRequest('user/deleteUsersList', usersList)
}

export const addFiles = async (files) => {

    if (!files.length) {
        throw new Error('Нет файлов для загрузки');
    }

    const attachmentFiles = new FormData();

    files.forEach(item => {
        attachmentFiles.append('files', item)
    });

    const response = await fetch(`${url}/rest/file/upload`, {
        method: 'post',
        body: attachmentFiles
    });

    if (!response.ok) {
        throw new Error(`Ошибка загрузки файлов, HTTP статус ${response.status}`);
    }

    const filesUpload = await response.json();

    if (!filesUpload || filesUpload.length === 0) {
        throw new Error('Нет загруженных файлов');
    }

    return filesUpload;
}

export const downloadFile = (guid, fileName) => async () => {
    const response = await fetch(`${url}/rest/file/${guid}/download`, {
        method: 'get',
        responseType: 'blob'
    })

    if (!response.ok) {
        throw new Error(`Ошибка получения файла, HTTP статус ${response.status}`);
    }

    const file = await response.blob();


    if (file) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([file]));
        link.setAttribute('download', `${fileName}`);
        if (document.body) {
            document.body.appendChild(link);
        }
        link.click();
        document.body.removeChild(link);
    }
}

