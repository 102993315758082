import React, {Fragment} from 'react';
import './styleListNomer.css';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function ListNomer (props){


        const classes = useStyles();
        return (





                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Дата</TableCell>
                                <TableCell align="right">Марка</TableCell>
                                <TableCell align="right">Номер</TableCell>
                                <TableCell align="right">Закупка</TableCell>
                                <TableCell align="right">Продажа</TableCell>
                                <TableCell align="right">Сумма</TableCell>
                                <TableCell align="right">Комментарий</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.auto.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" align="right">{row.date.substring(0,10)}</TableCell>
                                    <TableCell align="right">{row.marka}</TableCell>
                                    <TableCell align="right">{row.nomer}</TableCell>
                                    <TableCell align="right">{row.zakup}</TableCell>
                                    <TableCell align="right">{row.prodal}</TableCell>
                                    <TableCell align="right">{row.summa}</TableCell>
                                    <TableCell align="right">{row.comment}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


        );
    }

