import React, {Fragment} from 'react';
import './style3.css';
import {findNomer} from '../../Rest/RestFunction.js';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import ListNomer from "../ListNomer";
import ListAuto from "../ListAuto";
import {findDate} from "../../Rest/RestFunction";

const style = {
    marginLeft:50
}

export default class FindByNumber extends React.Component {
    constructor() {
        super();
        this.state = {

            requestAuto:false,

            auto: [],

            count: {
                nomer: "",
            }
        }
    }

    ch = (e) => {
        this.setState({
            count: {
                ...this.state.count,
                [e.target.name]: e.target.value
            }
        })

    }


    findNomer1 = async (item) => {
        let findnomerone = await findNomer( this.state.count.nomer)
        this.setState(
            {
                auto: findnomerone,
                requestAuto: true,
            }
        )
    } ;

    enterDown = (e) => {
        if(e.keyCode === 13) {
            this.findNomer1()
        }
    }

    resetAuto = () => {
        this.findNomer1()
    }

    render() {
        return (
            <div className="App_Find_Number">

                <h2> Поиск по номеру: </h2>

                <TextField
                    id="text_Find_Number"
                    name="nomer"
                    label="Номер"
                    multiline
                    rowsMax={4}
                    onChange={this.ch}
                    value={this.state.count.nomer}
                    onKeyDown={this.enterDown}
                /><br/><br/>

                <form className="form_Find_Number">

                    <Button variant="contained"
                            onClick={this.findNomer1}>
                        Запросить
                    </Button>

                    <br/>
                </form>
                    {this.state.requestAuto === true ?

                        this.state.auto.length>0 ?
                            <div className="table_center_Find_Number">
                                <div className="indent_Find_Number">
                            <ListAuto
                                auto={this.state.auto}

                                resetList = {this.resetAuto}
                            />
                                </div>
                            </div>
                            :
                            <div className="notFind_Find_Number">
                            <p6>Ничего не найдено</p6>
                            </div>
                    :
                       null
                    }


            </div>




        );

    }
}

