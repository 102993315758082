import React, {Fragment} from 'react';
import './styleFindAllUsers.css';
import {getAllUsers} from '../../Rest/RestFunction.js';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import ListAllUsers from "../ListAllUsers";


const style = {
    marginLeft:50
}

export default class FindAllUsers extends React.Component {
    constructor() {
        super();
        this.state = {

            requestAuto: false,
            auto: [],

        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.enterDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.enterDown)
    }


    getAllUsers = async () => {
        let auto1 = await getAllUsers()
        this.setState(
            {
                auto: auto1,
                requestAuto: true
            }
        )
    };


    resetUsers = () => {
        this.getAllUsers()
    }

    enterDown = (e) => {
        if(e.keyCode === 13) {
            this.getAllUsers()
        }
    }


    render() {
        return (
            <div className="App_Find_All_Users" >
                <div className="inline_Find_All_Users">
                <h2> Найти всех пользователей: </h2>

                {/*<Button variant="contained" style={{marginLeft:"10px", backgroundColor:"rgba( 102, 153, 255, 0.75)"}}*/}
                {/*        onClick={this.getAllUsers}>*/}
                    <Button variant="contained" style={{marginLeft:"10px"}}
                            onClick={this.getAllUsers}>
                    Найти
                </Button>
                </div>
                <br/>


                <div>
                    {this.state.requestAuto === true ?
                        this.state.auto.length > 0 ?
                            <div className="table_center_Find_AllUsers">
                                <div className="indent_Find_AllUsers">
                                    < ListAllUsers users={this.state.auto} resetUsersList={this.resetUsers}/>
                                </div>
                            </div>
                            : "Ничего не найдено"
                        : null
                    }
                </div>

            </div>

        );

    }
}

