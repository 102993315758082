import React, {Component} from 'react'
import {DropzoneArea} from 'material-ui-dropzone'
import { AttachFile, Description, PictureAsPdf, Theaters } from '@material-ui/icons';

class DropzoneAreaAuto extends Component{
    constructor(props){
        super(props);
        this.state = {
            files: this.props.files
        };
    }


    handlePreviewIcon = (fileObject, classes) => {
        const {type} = fileObject.file
        const iconProps = {
            className : classes.image,
        }

        if (type.startsWith("video/")) return <Theaters {...iconProps} />

        switch (type) {
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return <Description {...iconProps} />
            case "application/pdf":
                return <PictureAsPdf {...iconProps} />
            default:
                return <AttachFile {...iconProps} />
        }
    }

    render(){
        return (
            <DropzoneArea
                filesLimit = {10}
                dropzoneText={"Перетащите файлы сюда"}
                onChange={this.props.handleChangeFiles}
                showFileNames={true}
                initialFiles={this.props.files}
            />
        )
    }
}

export default DropzoneAreaAuto;