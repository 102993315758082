import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import "./styleListAuto.css"
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {style} from "@material-ui/system";
import {deleteByIdList, downloadFile} from "../../Rest/RestFunction";
import {shallowEqual, useSelector} from "react-redux";
import * as AuthSelectors from "../../store/auth/reducer";

import Word from '../../static/typeFiles/Word.png'
import Excel from '../../static/typeFiles/Excel.jpg'
import PDF from '../../static/typeFiles/PDF.jpg'
import JPG from '../../static/typeFiles/JPG.jpg'
import {renderFiles} from "../../utils/commonUtils";




function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'date', align: 'left', disablePadding: false, label: 'Дата' },
    { id: 'marka', align: 'left', disablePadding: false, label: 'Марка' },
    { id: 'nomer', align: 'left', disablePadding: false, label: 'Номер' },
    { id: 'zakup', align: 'left', disablePadding: false, label: 'Закупка' },
    { id: 'prodal', align: 'left', disablePadding: false, label: 'Продажа' },
    { id: 'prodal', align: 'left', disablePadding: false, label: 'Доставка' },
    { id: 'summa', align: 'left', disablePadding: false, label: 'Сумма' },
    { id: 'comment', align: 'left', disablePadding: false, label: 'Комментарий' },
    { id: 'files', align: 'center', disablePadding: false, label: 'Файлы' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead >

            <TableRow >

                {props.mycheckBox === "Администратор"? <TableCell  className="headCheckBoxBorder" padding="checkbox">
                <Checkbox
                    style={{fontWeight:"bold"}}
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                />
            </TableCell>
                : null}


                {headCells.map((headCell) => (
                    <TableCell
                        className="headBold"
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    const deleteElements = (value) => async () => {
        await deleteByIdList(value)
        props.resetList()
        props.setSelected([])
    }

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    Выбрано {numSelected}
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                   
                </Typography>
            )}
            {props.mycheckBox=== "Администратор"?
            numSelected > 0 ? (
                <Tooltip title="Удалить выбранные">
                    <IconButton onClick={deleteElements(props.selected)} aria-label="delete">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )
            : null}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

    export default function ListAuto(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const rows = props.auto;
    const user = useSelector(AuthSelectors.getUser, shallowEqual);
    const mycheckBox = user.role.name;

   
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const getSumma = (rows) => {
        var summa = 0
        rows.forEach(row => {
            summa+=row.summa
        })
        return summa
    }

    const getMoneyString = (value) => {
        value = String(value)
        for(let i = value.length-3; i>0; i-=3){
            value = value.substring(0,i)+"'"+value.substring(i)
        }

        return value + " ₽"
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


    return (

        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length}
                                      selected={selected}
                                      mycheckBox = {mycheckBox}
                                      setSelected={setSelected}
                                      resetList = {props.resetList}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead

                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            mycheckBox = {mycheckBox}
                        />
                        <TableBody >
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemSelected}
                                        >
                                            {mycheckBox === "Администратор" ?
                                            <TableCell  padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            : null}

                                            <TableCell className="miniPadding" style={{width:"80px", minWidth:"80px"}} component="th" align="left">{row.date.substring(0,10)}</TableCell>
                                            <TableCell className="miniPadding" style={{width:"80px", minWidth:"80px"}}align="left">{row.marka}</TableCell>
                                            <TableCell className="miniPadding" style={{width:"80px", minWidth:"80px"}} align="left"><Link to={`/auto/${row.id}`} >{row.nomer}</Link></TableCell>
                                            <TableCell className="miniPadding"  style={{width:"40px", minWidth:"40px"}} align="left">{getMoneyString(row.zakup)}</TableCell>
                                            <TableCell className="miniPadding"  style={{width:"40px", minWidth:"40px"}} align="left">{getMoneyString(row.prodal)}</TableCell>
                                            <TableCell className="miniPadding"  style={{width:"40px", minWidth:"40px"}} align="left">{getMoneyString(row.dostavka)}</TableCell>
                                            <TableCell className="miniPadding"  style={{width:"40px", minWidth:"40px"}} align="left">{getMoneyString(row.summa)}</TableCell>
                                            <TableCell className="miniPadding"  style={{width:"80px", minWidth:"80px"}} align="left">{row.comment}</TableCell>
                                            <TableCell className="miniPadding listAuto_div_small"  style={{width:"130px", minWidth:"130px"}} align="right">{renderFiles(row.files)}</TableCell>

                                        </TableRow>
                                    );
                                })}
                            <TableRow >
                                {mycheckBox === "Администратор"?
                                    <TableCell className="notBorderBottom" padding="checkbox">

                                    </TableCell>
                                    : null}

                                <TableCell className="notBorderBottom" component="th" align="right">{}</TableCell>
                                <TableCell className="notBorderBottom" align="right">{" "}</TableCell>
                                <TableCell className="notBorderBottom"  align="right">{" "}</TableCell>
                                <TableCell  className="notBorderBottom" align="right">{" "}</TableCell>
                                <TableCell  className="notBorderBottom" align="right">{" "}</TableCell>
                                <TableCell  className="notBorderBottom fatText" align="right">{"Итого: "}</TableCell>
                                <TableCell className="notBorderBottom miniPadding fatText" align="left">{getMoneyString(getSumma(rows))}</TableCell>
                                <TableCell className="notBorderBottom"  align="right">{" "}</TableCell>
                                <TableCell className="notBorderBottom"  align="right">{" "}</TableCell>
                            </TableRow >

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[30, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage="Количество на странице"
                    labelDisplayedRows={
                        ({ from, to, count }) =>
                        {
                            return from+"-"+to+" из "+count
                        }
                    }
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Paper>
                {/*<Button variant="contained" color="secondary">*/}
                {/*    Удалить*/}
                {/*</Button>*/}

        </div>
    );
}