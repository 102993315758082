import React, {Fragment} from 'react';
import './styleAddUsers.css';
import {addUser} from '../../Rest/RestFunction.js';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';



export default class AddUsers extends React.Component {
    constructor() {
        super();
        this.state = {

            responseAddUser:{
                message:"",
                typeResult:"success"
            },
            showMessage:false,

            countTextField: {
                login:true,
                password:true,
                id_role:true,

            },

            count: {
                login: "",
                password:"",
                id_role:1,
                

            }
        }
    }

    ch = (e) => {
        this.setState({
            count: {
                ...this.state.count,
                [e.target.name]: e.target.value
            }
        })
    }

    add = async (item) => {
        if (this.state.count.login.length>=2 && this.state.count.password.length>=2 && this.state.count.id_role!=null) {
            let responseAddUser=await addUser(this.state.count);
            this.setState({
                count: {
                    ...this.state.count,
                    login: "",
                    password:"",

                },
                responseAddUser:responseAddUser,
                showMessage:true,
            })
        }
        else{
            this.setState({
                countTextField:{
                    ...this.state.countTextField,
                    login:false
                },

            })
            setTimeout(() => {
                this.setState({
                    countTextField:{
                        ...this.state.countTextField,
                        login:true
                    },

                })},2000)
        }
    };

    messageClose = () => {
        this.setState({
            showMessage:false
        })
    }


    render() {
        return (

            <div className="App_AddUser">

                <h2> Добавить пользователя: </h2>

                <br/>

                <div className="form_AddUser">

                    <TextField
                        id="text_AddUser"
                        name="login"
                        value={this.state.count.login}
                        className={this.state.countTextField.login ? "" : "red_animation_AddUser"}
                        label="Login"
                        multiline
                        rowsMax={4}
                        onChange={this.ch}
                    />

                    <br/>
                    <TextField
                        id="text_AddUser"
                        name="password"
                        value={this.state.count.password}
                        className={this.state.countTextField.login ? "" : "red_animation_AddUser"}
                        label="Password"
                        multiline
                        rowsMax={4}
                        onChange={this.ch}
                    />
                    <br/>
                        <Select
                            name="id_role"
                            value={this.state.count.id_role}
                            onChange={this.ch}
                        >
                            <MenuItem value={1}>Пользователь</MenuItem>
                            <MenuItem value={2}>Администратор</MenuItem>
                        </Select>

                    <br/><br/><br/>

                    <Button variant="contained"
                            onClick={this.add}>
                        Добавить
                    </Button>

                    <br/>

                </div>

                <Snackbar open={this.state.showMessage} autoHideDuration={3000} onClose={this.messageClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.messageClose} severity={this.state.responseAddUser.typeResult}>
                        {this.state.responseAddUser.message}
                    </MuiAlert>
                </Snackbar>

            </div>

        );
    }
}
