import React, {Fragment} from 'react';
import './styleListCount.css';


export default class ListCount extends React.Component {
    constructor() {
        super();

    }


    render() {
        return (
            <div className="App">

                {this.props.auto.map((auto, index) => {
                    return (
                        <div key={index} className="table-row">

                            <div>
                               {auto.date}
                            </div>
                            <div>
                                {auto.marka}
                            </div>
                            <div>
                                {auto.nomer}
                            </div>
                            <div>
                                {auto.zakup}
                            </div>
                            <div>
                                {auto.prodal}
                            </div>
                            <div>
                                {auto.summa}
                            </div>
                            <div>
                                {auto.comment}
                            </div>
                        </div>
                    )
                })}
            </div>
        );

    }
}

