import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {store} from "./store/configureStore";
import Main from './components/Main';
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';


import './index.css';

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <Main />
        </Provider>
    </CookiesProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
