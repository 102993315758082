import React, {Fragment} from 'react';
import './styleUserUpadate.css';
import {getUser, delRest, updateUser} from '../../Rest/RestFunction';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";


const style = {
    marginLeft:50
}

export default class AutoUpdate extends React.Component {
    constructor() {
        super();
        this.state = {
            responseBack:{

                typeResult:"success",
            },

            showMessage:false,

            countTextField: {
                login:true,
                password:true,
                id_role:true,
            },

            count: {
                login: "",
                password:"",
                id_role:1

            }
        }
    }

    async componentDidMount() {
        // const {routeProps: {match: {params: {id,name,role}}}} = this.props;
        const id = this.props.user.params.id;
        let count = await getUser(id)
        this.setState({count: count})
    }

    ch = (e) => {
        this.setState({
            count: {
                ...this.state.count,
                [e.target.name]: e.target.value
            }
        })
    }

    userUpdate = async (item) => {
        if (this.state.count.login.length>=2 && this.state.count.password.length>=2 && this.state.count.id_role!=null) {
            const responseUpdateUser = await updateUser(this.state.count, this.props.authUser.id);
            this.setState({
                count: {
                    ...this.state.count,
                    login: "",
                    password:"",
                },
                responseBack:responseUpdateUser,
                showMessage:true,
            })
        }
        else{
            this.setState({
                countTextField:{
                    ...this.state.countTextField,
                    login:false
                }
            })
            setTimeout(() => {
                this.setState({
                    countTextField:{
                        ...this.state.countTextField,
                        login:true
                    }
                })},2000)
        }
    };

    del = async (item) => {
        await delRest( this.state.id)
    } ;

    messageClose = () => {
        this.setState({
            showMessage:false
        })
    }

    render() {
        return (
            <div className="App_User_Update">

                <h2> Обновить данные пользователя: </h2>

                <br/>

                <div className="form_User_Update">

                    <TextField
                        id="text_User_Update"
                        name="login"
                        value={this.state.count.login}
                        className={this.state.countTextField.login ? "" : "red_animation_User_Update"}
                        label="Login"
                        multiline
                        rowsMax={4}
                        onChange={this.ch}
                    />

                    <br/>
                    <TextField
                        id="text_User_Update"
                        name="password"
                        value={this.state.count.password}
                        className={this.state.countTextField.login ? "" : "red_animation_User_Update"}
                        label="Password"
                        multiline
                        rowsMax={4}
                        onChange={this.ch}
                    />
                    <br/>
                    {/*/} <InputLabel id="demo-simple-select-label">Роль</InputLabel>*/}
                    <Select
                        name="id_role"
                        value={this.state.count.id_role}
                        onChange={this.ch}
                    >
                        <MenuItem value={1}>Пользователь</MenuItem>
                        <MenuItem value={2}>Администратор</MenuItem>
                    </Select>

                    <br/>

                    <br/><br/>

                    <Button variant="contained"
                            onClick={this.userUpdate}>
                        Обновить
                    </Button>

            </div>
                <Snackbar open={this.state.showMessage} autoHideDuration={3000} onClose={this.messageClose}>
                    <MuiAlert elevation={6} variant="filled" onClose={this.messageClose} severity={this.state.responseBack.typeResult}>
                        {this.state.responseBack.message}
                    </MuiAlert>
                </Snackbar>
            </div>


        );

    }
}

