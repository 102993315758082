import React, {Component} from "react";
import { connect } from 'react-redux';

import {ConnectedRouter} from "connected-react-router";
import {Route, Switch} from "react-router-dom";

/** store **/
import {history} from "../../store/configureStore";

/** styles **/
import './style.css';
import AddAuto from "../AddAuto";
import FindDate from "../FindDate";
import FindByNumber from "../FindByNumber";
import FindLineDate from "../FindLineDate";

import NavMenu from "../NavMenu";
import LoginPassword from "../LoginPassword";
import AutoUpdate from "../AutoUpdate";
import AddUsers from "../AddUsers";
import FindAllUsers from "../FindAllUsers";
import UserUpdate from "../UserUpdate";
import GeneralSearch from "../FindGeneral";

import * as AuthActions from "../../store/auth/action"
import * as AuthSelectors from "../../store/auth/reducer"
import FindGeneral from "../FindGeneral";



class Main extends Component {

    constructor() {
        super();
    }

    componentDidMount() {
        this.props.checkUser()
        let authResponse = this.props.user
        this.setState({
            auth: this.props.user.auth,
            id: this.props.user.id,
            login: this.props.user.login,
            role: this.props.user.role
        })
    };

    render() {
        return this.props.user.auth === true ?
        (
            <ConnectedRouter history={history}>
                <main className='main-wrapper'>

                    <div className="main-page">
                    <NavMenu/>
                    <Switch>
                        <Route exact path="/" component={AddAuto} />
                        {/*<Route exact path="/findDate" component={FindDate}/>*/}
                        {/*<Route exact path="/findByNumber" component={FindByNumber}/>*/}
                        {/*<Route exact path="/findLineDate" component={() => <FindLineDate role = {this.props.user.role} />}  />*/}
                        {/*<Route exact path="/findLineDate" component={FindLineDate}/>*/}
                        <Route exact path="/findGeneral" component={(props) => <FindGeneral user = {this.props.user}/>}/>
                        <Route exact path="/auth" component={LoginPassword }/>
                        <Route exact path="/add" component={this.props.user.role.name==="Администратор" && AddUsers} />
                        <Route exact path="/findUsers" component={this.props.user.role.name==="Администратор" && FindAllUsers} />
                        {/*{<Route path="/user/:id" component={(props) => <UserUpdate role = {this.state.role} user = {props.match} />} />}*/}
                        {<Route path="/user/:id" component={(props) => this.props.user.role.name==="Администратор" &&<UserUpdate authUser = {this.props.user} user = {props.match} />} />}
                        {<Route path="/auto/:id" component={(props) => <AutoUpdate match = {props.match} role = {this.props.user.role} userId = {this.props.user.id} />} />}
                        {/*<Route path="/auto/:id" component={AutoUpdate} />} />*/}
                    </Switch>
                    </div>
                </main>
            </ConnectedRouter>
        )
            :
            <LoginPassword/>

    }
}

//Selectors
const mapStateToProps = (state) => {
    return {
        user: AuthSelectors.getUser(state),
    }
};

//UseDispatch - это в функциональном компоненте
const mapDispatchToProps = dispatch => {
    return {
        checkUser: () => {
            dispatch(AuthActions.checkAuth())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main)
